import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 560,
			md: 768,
			lg: 1200,
			xl: 1536,
			xxl: 1921,
		},
	},
	palette: {
		mode: 'light',
		primary: {
			main: grey[900],
			contrastText: '#FAFAFA',
		},
		secondary: grey,
		background: {
			default: '#FAFAFA',
		},
	},
	typography: {
		h1: { fontWeight: 300 },
		h2: { fontWeight: 300 },
		h3: { fontWeight: 300 },
		h4: { fontWeight: 300 },
		h5: { fontWeight: 300 },
		h6: { fontWeight: 300 },
		body1: {
			fontSize: '1.2rem',
			fontWeight: 300,
		},
		body2: { fontWeight: 300, },
		overline: { fontWeight: 300, },
		fontFamily: [
			'"Lato"',
			'"Helvetica Neue"',
			'-apple-system',
			'BlinkMacSystemFont',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
	},
	shadows: [
		'none',
		'0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07)',
		'0 4px 16px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 3%)',
		'0 4px 16px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 3%)',
		'0 4px 16px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 3%)',
		'0 4px 16px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 3%)',
		'0 4px 16px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 3%)',
		'0 4px 16px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 3%)',
		'0 4px 16px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 3%)',
		'0 4px 16px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 3%)',
		'0 4px 16px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 3%)',
		'0 4px 16px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 3%)',
		'0 4px 16px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 3%)',
		'0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07)',
		'0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07)',
		'0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07)',
		'0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07)',
		'0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)',
		'0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)',
		'0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)',
		'0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)',
		'0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07), 0 64px 128px rgba(0,0,0,0.07)',
		'0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07), 0 64px 128px rgba(0,0,0,0.07)',
		'0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07), 0 64px 128px rgba(0,0,0,0.07)',
		'0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07), 0 64px 128px rgba(0,0,0,0.07)',
	],
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				html: {
					overflowX: 'hidden',
					overflowY: 'auto',
					scrollBehavior: 'smooth',
				},
				body: {
					'& .MuiTouchRipple-root': {
						borderRadius: 0,

						'& *': {
							background: grey[300],
							borderRadius: 0,
						},
					},
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					background: '#FAFAFA',
				}
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				},
			},
		},
		MuiTouchRipple: {
			styleOverrides: {
				root: {
					borderRadius: '0px !important',
					background: 'red !important',
				},
				child: {
					borderRadius: '0px !important',
					background: 'green !important',
				},
				ripple: {
					borderRadius: '0px !important',
					background: 'blue !important',
				}
			},
		},
	},
});

export default theme;
